import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import axios from "../MyAxios";
import { toast } from "react-toastify";
import { DELETE_POST, POST, REPORT_FORM } from "../constants/api";
import PostButton from "./PostButton";
import { useTheme } from "@emotion/react";
import share from "../assets/img/share.svg";
import link from "../assets/img/link.svg";
import commentimg from "../assets/img/comment.svg";
import report from "../assets/img/report.svg";
import saved from "../assets/img/saved.svg";
import save from "../assets/img/save.svg";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { calTime, parseHTML, regexReplacers } from "../helpers";
import deleteImg from "../assets/img/deleteDark.svg";
import { useDispatch, useSelector } from "react-redux";
import backImg from "@images/back.svg";
import VoteDownImg from "@images/voteDown.svg";
import VoteDownGreenImg from "@images/voteDownGreen.svg";
import { CircularProgress } from "@mui/material";
import Loader from "./Loader";
import ExpandableText from "./ExpandableText";
import ImageModal from "./ImageModal";
import {
  setClickedPostId,
  setPostSubscribeStatus,
} from "@redux/prevPostsSlice";
import {
  Delete as DeleteIcon,
  NotificationsActive,
  NotificationsNoneOutlined,
} from "@mui/icons-material";
import ShareDialog from "./share-post/ShareDialog";

export default function Post({
  noHeader = false,
  group,
  post,
  setPost,
  comment,
  savedItems,
  setDeletedId,
  removePost,
  back,
  setGetSaved,
  getSaved,
  canRemoveCommunityGroupPost = false,
  handleRemoveCommunityGroupPost,
  fullDescription = false,
  readOnly = false,
  canDeletePost = false,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userRecord } = useSelector((state) => state.user);

  const [isSaved, setIsSaved] = React.useState(false);
  const [reload, setReload] = React.useState(0);
  const [ownPost, setOwnPost] = React.useState(false);
  // const [voteCount, setVoteCount] = React.useState(0);
  const [upvoteCount, setUpvoteCount] = React.useState(0);
  const [downvoteCount, setDownvoteCount] = React.useState(0);
  const [isUpvoted, setIsUpvoted] = React.useState(false);
  const [isDownvoted, setIsDownvoted] = React.useState(false);
  const [upvoteLoading, setUpvoteLoading] = React.useState(false);
  const [downvoteLoading, setDownvoteLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [votesLoading, setVotesLoading] = React.useState(false);
  const [openImageModal, setOpenImageModal] = React.useState(false);
  
  const [postShareDialog, setPostShareDialog] = React.useState(false);

  // const [isPostSubscribed, setIsPostSubscribed] = React.useState(
  //   post?.isSubscribed
  // );

  const avatarUrl = group?.icon?.avatarUrl || post?.user?.media?.avatarUrl;

  const name =
    group?.name || post?.user?.firstName + " " + post?.user?.lastName;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  React.useEffect(() => {
    if (!post?.id) return;
    setVotesLoading(true);
    axios
      .get(`/post/vote-status/${post?.id}`)
      .then((res) => {
        setIsUpvoted(res.data?.isUpvoted);
        setIsDownvoted(res.data?.isDownvoted);
        setUpvoteCount(res.data?.upvoteCount);
        setDownvoteCount(res.data?.downvoteCount);
        setVotesLoading(false);
      })
      .catch(() => {})
      .finally(() => {
        setVotesLoading(false);
      });
  }, [post?.id]);

  const handleClickedPostSave = () => {
    const postId = group ? `group-${group.id}-${post.id}` : `post-${post.id}`;
    dispatch(setClickedPostId(postId));
    localStorage.postClickTrigger = postId;
  }

  const viewPost = (id) => {
    if (id && comment) {
      try {
        handleClickedPostSave();
        navigate(`${POST}/${id}`);

      } catch (error) {
        toast.error(error);
      }
    }
  };

  const setSaved = () => {
    if (savedItems?.length > 0) {
      let flag = false;
      savedItems.forEach((item) => {
        if (post?.id === item?.postSaved) {
          flag = true;
        }
      });
      setIsSaved(flag);
    } else {
      setIsSaved(post?.isSaved);
    }
  };

  React.useEffect(() => {
    setSaved();
  }, [savedItems, post, group]);

  React.useEffect(() => {
    if (userRecord?.id && post?.user?.id && userRecord.id === post.user.id)
      setOwnPost(true);
  }, [userRecord, post]);

  const onDelete = async (postId) => {
    if (window.confirm("Are you sure you want to delete this post?")) {
      try {
        await axios.post(DELETE_POST, { postId }).then((res) => {
          toast.success(res.data?.message ?? "Post deleted successfully");
          if (removePost) removePost();
          setDeletedId?.(postId);
        });
      } catch (error) {}
    }
  };

  // Greater than 1 because poster themselves are a connection by default
  const postWasOnlySharedWithConnections = post?.connections?.length > 1 && !post.communities?.length && !post.groups?.length;
  const postGroupIsPrivate = post?.groups?.length > 0 && post.groups[0].visibility === 'Private';

  const shareExternallyDisabled = !isMobile || postGroupIsPrivate || postWasOnlySharedWithConnections;
  const shareInternallyDisabled = postWasOnlySharedWithConnections;

  const actions = [
    {
      id: 1,
      src: share,
      disabled: readOnly,
      shown: !shareInternallyDisabled || !shareExternallyDisabled,
      onClick: () => {
        if(shareInternallyDisabled) {
          let content = `${process.env.REACT_APP_BASE_URL ?? "http://localhost:3000"}/post/${post?.id}`;
          if (navigator.share) {
            navigator.share({
              title: content,
              url: content,
            });
          }

        } else {
          setPostShareDialog(true);
        }
      },
    },
    {
      id: 2,
      src: link,
      disabled: readOnly,
      shown: true,
      onClick: () => {
        navigator.clipboard.writeText(
          `${process.env.REACT_APP_BASE_URL ?? "http://localhost:3000"}/post/${post?.id}`
        );
        toast.success("Link copied to clipboard");
      },
    },
    {
      id: 3,
      src: commentimg,
      onClick: () => viewPost(post?.id),
      disabled: readOnly,
      shown: comment,
    },
    {
      id: 4,
      src: report,
      onClick: () => {
        navigate(REPORT_FORM, { state: { id: post?.id, report: "post" } });
      },
      disabled: readOnly,
      shown: true,
    },
    {
      id: 5,
      src: deleteImg,
      onClick: () => onDelete(post?.id),
      disabled: readOnly,
      shown: ownPost || canDeletePost,
      className: '-mt-[2px]'
    },
    {
      id: 6,
      src: save,
      onClick: () => onSavePost(post?.id),
      disabled: readOnly,
      shown: !ownPost && !isSaved,
    },
    {
      id: 7,
      src: saved,
      onClick: () => onUnSavePost(post?.id),
      disabled: readOnly,
      shown: !ownPost && isSaved,
    },
    {
      id: 8,
      onClick: () => handleRemoveCommunityGroupPost?.(post?.id),
      disabled: readOnly,
      shown: canRemoveCommunityGroupPost,
      render: (props) => <DeleteIcon {...props} fontSize="medium" className="-mt-1 ml-2" />
    },
  ];
  const onSavePost = async (id) => {
    try {
      console.log("saving post with id", id);
      await axios.post("/save/post", { id });
      toast.success("Post saved successfully");
      if (Array.isArray(savedItems)) {
        setGetSaved(!getSaved);
      } else setIsSaved(true);
      setReload(reload + 1);
    } catch (error) {}
  };

  const onUnSavePost = async (id) => {
    try {
      console.log("unsaving post with id", id);
      await axios.post("/unsave/post", { id });
      toast.success("Post unsaved successfully");
      if (Array.isArray(savedItems)) {
        setGetSaved(!getSaved);
      } else setIsSaved(false);
      setReload(reload + 1);
    } catch (error) {}
  };

  const onUpvotePost = () => {
    if(readOnly)
      return;

    setUpvoteLoading(true);
    axios
      .post("/post/upvote", { postId: post?.id })
      .then(() => {
        setUpvoteCount((c) => c + 1);
        setDownvoteCount((c) => (isDownvoted ? c - 1 : c));
        setIsUpvoted(true);
        setIsDownvoted(false);
      })
      .catch(() => {})
      .finally(() => {
        setUpvoteLoading(false);
      });
  };
  const onRemoveUpvotePost = () => {
    if(readOnly)
      return;

    setUpvoteLoading(true);
    axios
      .post(`/post/upvote`, { postId: post?.id, remove: true })
      .then(() => {
        setUpvoteCount((c) => c - 1);
        setIsUpvoted(false);
      })
      .catch(() => {})
      .finally(() => {
        setUpvoteLoading(false);
      });
  };
  const onDownVotePost = () => {
    if(readOnly)
      return;

    setDownvoteLoading(true);
    axios
      .post(`/post/downvote`, { postId: post?.id })
      .then(() => {
        setDownvoteCount((c) => c + 1);
        setUpvoteCount((c) => (isUpvoted ? c - 1 : c));
        setIsDownvoted(true);
        setIsUpvoted(false);
      })
      .catch(() => {})
      .finally(() => {
        setDownvoteLoading(false);
      });
  };

  const onRemoveDownVotePost = () => {
    if(readOnly)
      return;

    setDownvoteLoading(true);
    axios
      .post(`/post/downvote`, { postId: post?.id, remove: true })
      .then(() => {
        setDownvoteCount((c) => c - 1);
        setIsDownvoted(false);
      })
      .catch(() => {})
      .finally(() => {
        setDownvoteLoading(false);
      });
  };
  const onUnSubscribe = () => {
    dispatch(setPostSubscribeStatus({ postId: post?.id, isSubscribed: false }));
    if (setPost) setPost({ ...post, isSubscribed: false });
    try {
      axios.post("/post/unsubscribe", { postId: post?.id });
    } catch (e) {
      dispatch(
        setPostSubscribeStatus({ postId: post?.id, isSubscribed: true })
      );
      if (setPost) setPost({ ...post, isSubscribed: true });
    }
  };
  const onSubscribe = () => {
    dispatch(setPostSubscribeStatus({ postId: post?.id, isSubscribed: true }));
    if (setPost) setPost({ ...post, isSubscribed: true });
    try {
      axios.post("/post/subscribe", { postId: post?.id });
    } catch (e) {
      dispatch(
        setPostSubscribeStatus({ postId: post?.id, isSubscribed: false })
      );
      if (setPost) setPost({ ...post, isSubscribed: false });
    }
  };

  if (loading || !post)
    return <Loader />;

  const postTitle = post?.title
    ?.replace(regexReplacers.emoji.exp, regexReplacers.emoji.rep)
    ?.replace(regexReplacers.style.exp, regexReplacers.style.rep)
    ?.replace(regexReplacers.script.exp, regexReplacers.script.rep)
    ?? '';

  const postDescription = post?.description
    ?.replace(/https?:\/\/[^\s]+/g, (url) => `<a href="${url}" target="_blank" rel="noreferrer" class="underline cursor-pointer text-sky-600">${url}</a>`)
    ?.replace(regexReplacers.emoji.exp, regexReplacers.emoji.rep)
    ?.replace(regexReplacers.style.exp, regexReplacers.style.rep)
    ?.replace(regexReplacers.script.exp, regexReplacers.script.rep)
    ?? '';

  let parsedDescription = '';

  if(postDescription)
    parsedDescription = postDescription
      .replace(/\n/g, "<br>");

  return (
    <div>
      <Card className={clsx(" z-0 mx-auto mb-5 shadow-none")}>
        <CardHeader
          avatar={
            <div className="flex gap-3">
              {back && (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate(-1);
                  }}
                  src={backImg}
                  alt="back"
                  className="self-center cursor-pointer"
                />
              )}
              <div
                className="cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  if(noHeader && Boolean(group))
                    navigate(`/group/info/${group?.id}`);
                  else
                    navigate(`/${post?.user?.userName}`);
                }}
              >
                <Avatar src={avatarUrl} />
              </div>
            </div>
          }
          action={
            // !readOnly && (
              ownPost ? (
                <IconButton
                  disabled={readOnly}
                  sx={{ borderRadius: 99999 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/post/create/" + post?.id);
                  }}
                >
                  <PostButton>Edit</PostButton>
                </IconButton>
              ) : post?.isSubscribed ? (
                <IconButton disabled={readOnly} onClick={onUnSubscribe}>
                  <NotificationsActive />
                </IconButton>
              ) : (
                <IconButton disabled={readOnly} onClick={onSubscribe}>
                  <NotificationsNoneOutlined />
                </IconButton>
              )
            // )
          }
          title={
            !noHeader && (
              <Typography className="text-sm">
                <span
                  className="cursor-pointer font-semibold text-base"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/${post?.user?.userName}`);
                  }}
                >
                  {post?.user?.userName}
                </span>
                <small
                  style={{ color: theme.palette.text.dark }}
                  className="ml-2"
                >
                  {calTime(post?.createdAt)}
                </small>
              </Typography>
            )
          }
          subheader={
            <div className="flex items-center gap-2">
              {Boolean(group) ? (
                <Link to={`/group/info/${group.id}`}>
                  <Typography className={noHeader ? "text-sm font-semibold" : "text-xs"}>
                    {name}
                  </Typography>
                </Link>
              ) : (
                <Typography className={noHeader ? "text-sm font-semibold" : "text-xs"}>
                  {name}
                </Typography>
              )}
              {noHeader && (
                <small
                  className="mt-1"
                  style={{ color: theme.palette.text.dark }}
                >
                  {calTime(post?.createdAt)}
                </small>
              )}
            </div>
          }
        />
        <div className="cursor-pointer" onClick={() => viewPost(post?.id)}>
          <div className="mx-4 mb-2">
            <Typography
              variant="body2"
              className="font-semibold text-base"
            >
              {parseHTML(postTitle)}
            </Typography>
            {postDescription && (
              fullDescription ? (
                <Typography
                  variant="body2"
                  className="break-words text-base"
                >
                  {parseHTML(parsedDescription)}
                </Typography>
              ) : (
                <ExpandableText text={postDescription} />
              )
            )}
            <div className="flex gap-1 mt-3 flex-wrap">
              {post?.tags?.map((tag, key) => (
                <Typography
                  key={key}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/search?search=%23${tag.subcategory}`);
                  }}
                  variant="body2"
                  color="primary"
                  className="font-semibold"
                >
                  {`{${tag.subcategory}}`}
                  {key < post.tags.length - 1 ? ',' : ''}
                </Typography>
              ))}
            </div>
          </div>

          {post?.media?.avatarUrl &&
            (post?.media?.fileType === "video" ? (
              <CardMedia
                controlsList="nodownload"
                crossOrigin="anonymous"
                component={"video"}
                controls
                className="mx-auto h-52 w-11/12 rounded-2xl object-contain"
                image={post?.media?.avatarUrl}
                alt="Stream Media"
                muted
              />
            ) : (
              <CardMedia
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenImageModal(true);
                }}
                component={"img"}
                className="mx-auto h-52 w-11/12 rounded-2xl object-contain"
                image={post?.media?.avatarUrl}
                alt="Stream Media"
              />
            ))}
        </div>

        <CardActions
          onClick={(e) => e.stopPropagation()}
          disableSpacing
          className="mt-3 flex justify-between"
        >
          <div className="flex">
            {actions.map((action) => (
              <div key={action.id}>
                {action.shown && (
                  action.render ? (
                    action.render({
                      key: action.id,
                      style: { opacity: action.disabled ? 0.2 : 1 },
                      onClick: action.disabled ? null : action.onClick,
                      alt: "Action"
                    })
                  ) : (
                    <img
                      src={action.src}
                      key={action.id}
                      className={clsx("mx-3 cursor-pointer", action.className ?? '')}
                      style={{ opacity: action.disabled ? 0.2 : 1 }}
                      onClick={action.disabled ? null : action.onClick}
                      alt="Action"
                    />
                  )
                )}
              </div>
            ))}
          </div>
          {votesLoading && <CircularProgress size="1rem" />}
          <div
            style={{ backgroundColor: "#62626214" }}
            className={clsx(
              "flex gap-2 py-1 px-3 rounded-full items-center",
              votesLoading && "hidden"
            )}
          >
            <Typography className="mt-1" color="primary">
              {upvoteCount}
            </Typography>
            {upvoteLoading && <CircularProgress size="1rem" />}
            {!upvoteLoading &&
              (isUpvoted ? (
                <img
                  alt="up"
                  style={{ height: "15px", opacity: readOnly ? 0.3 : 1 }}
                  className="rotate-180 cursor-pointer"
                  src={VoteDownGreenImg}
                  onClick={() => onRemoveUpvotePost()}
                />
              ) : (
                <img
                  alt="up"
                  style={{ height: "15px", opacity: readOnly ? 0.3 : 1 }}
                  className="rotate-180 cursor-pointer"
                  src={VoteDownImg}
                  onClick={() => onUpvotePost()}
                />
              ))
            }

            {downvoteLoading && <CircularProgress size={"1rem"} />}

            {!downvoteLoading &&
              (isDownvoted ? (
                <img
                  alt="down"
                  style={{ height: "15px", opacity: readOnly ? 0.3 : 1 }}
                  color="green"
                  className="cursor-pointer"
                  src={VoteDownGreenImg}
                  onClick={() => onRemoveDownVotePost()}
                />
              ) : (
                <img
                  alt="down"
                  style={{ height: "15px", opacity: readOnly ? 0.3 : 1 }}
                  color="green"
                  className="cursor-pointer"
                  src={VoteDownImg}
                  onClick={() => onDownVotePost()}
                />
              ))
              }
            <Typography className="mt-1" color="primary">
              {downvoteCount}
            </Typography>
          </div>
        </CardActions>
        {comment && (
          <button onClick={() => viewPost(post?.id)}>
            <Typography variant="body2" color="text.secondary" className="ml-4">
              View {post?.comments} comments
            </Typography>
          </button>
        )}
      </Card>
      {post?.media?.fileType !== "video" && (
        <ImageModal
          url={post?.media?.avatarUrl}
          open={openImageModal}
          setOpen={setOpenImageModal}
        />
      )}

      <ShareDialog
        post={post}
        open={postShareDialog}
        onClose={() => setPostShareDialog(false)}
      />
    </div>
  );
}
